
.interactive-segment {
  --background: #fff;
}

.interactive-segment-btn {
  --background: #fff !important;
  --color: 3 d5a59 !important;
  --color-checked: #3d5a59 !important;
  --background-checked: #fff !important;
}
